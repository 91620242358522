import Category from "../Category/Category"
import Tshirt from "../Category/Tshirt"
import Slider from "../Slider/Slider"




const Home = () => {

  
  
  return (

    <div>
        <Slider/>
      <Category/>
    <Tshirt/>
      

    </div>
  )
  }
export default Home